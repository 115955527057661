/* Navbar styles */
.navbar {
    background-color: rgba(236,224,208,1);
    padding: 0 20px;
    /* overflow: hidden; */
    height: 65px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navbar-container {
    padding-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: row; */
}

.navbar-list {
    list-style: none;
    display: flex;
    padding: 0 20px;
}

.navbar-item {
    margin: 0 5px;
}

.navbar-item a {
    text-decoration: none;
    transition: color .3s ease-out,background-color .3s ease-out;
    padding: .5rem 1rem;
    color: #222;
    border-radius: 4px;
}

.navbar-item a:hover {
    color: #555;
    background-color: white;
}

#External-link {
    color: #a72626;
    text-decoration: none;
    font-weight: bold;
}

#serverIpButton {
    background-color: transparent;
    /* background-color: #000000; */
    color: rgb(223, 37, 30);
    border: none;
    font-weight: bold;
    border-radius: 4px;
    padding: .5rem 1rem;
    cursor: pointer;
    align-self: left;
    float:left;
    position:absolute;
    text-align: center;
    top: 25%;
    
}
.dropdown-content > a:hover {
    background-color: #fff;
}
.dropdown:hover > a:not(:hover) {
    background-color: #ddd;
}



.dropdown-content {
    padding-top: 10px;
    justify-items: center;
    display: none;
    position: absolute;
    width: auto;
}

.dropdown:hover .dropdown-content {
    display: flex;
}

.dropdown-content a {
    background-color: #ddd;
    padding: 10px 20px
}


@media (max-width: 800px) {
    #serverIpButton {
        display: none;
    }
}

/* .navbar-brand {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.navbar-nav {
    list-style: none;
    display: flex;
}

.navbar-nav li {
    margin-right: 10px;
}

.navbar-nav li a {
    text-decoration: none;
    color: #333;
    padding: 5px;
}

.navbar-nav li a:hover {
    color: #555;
} */