.About {
    align-items: center;
    justify-content: center;
    text-align: center;
}

p#About-text {
    font-size: 25px;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width: 800px) {
    p#About-text {
        font-size: 14px;
    }
}