.Rules p
, .Rules h1
, .Rules h2
{
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    font-family: Helvetica, sans-serif, Arial;
}

.Rules-title {
    text-align: center;
    font-size: clamp(10px,7vh,100px);
    font-weight: bold;
    color: #333;
}

.Rules-intro {
    text-align: center;
    font-size: clamp(10px,2vh,20px);
    margin-top: 10px;
}

#Rules-container, #Rules-container > * {
    text-align: center;
    list-style-position: inside;
}

.Rules-section-list {
    padding: 0;
    margin: 0;
}

.Rules-section-item {
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
}