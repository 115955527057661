body {
    background-image: url("./assets/SwampyBackground.png");
    background-attachment: fixed;
    background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Text-logo {
    height: 40vmin;
    padding: 20px;
    max-width: 100%;
    box-sizing: border-box;
}

#Container {
    background: linear-gradient(180deg, rgba(236,224,208,1) 0%, rgba(255,184,181,1) 100%);
    width: 65%;
    position: absolute;
    min-height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
}

.App-header {
  background-color: rgba(236,224,208,1);
  /* border-bottom: 2px;
  font-size: 12pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

@media screen and (max-width: 800px) {
    #Container {
        width: 100%;
    }
}